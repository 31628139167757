export function appData() {
	// console.log(process.env.beURL);
	return {
		authPW: true,
		beURL: process.env.beURL,
		bePATH: process.env.bePATH,
		beAPI: process.env.beAPI,
		// currentScreen: 'intro',
		currentScreen: 'season-over',
		isDarkMode: true,
		gameInfo: null,
		boxScore: null,
		isPlayingTutorial: false,
		locationGranted: false,
		userLatitude: null,
		userLongitude: null,
		isInside: false,
		isPlayingGame: false,
		loadingGameInfo: false,
		progressValueTutorial: 0.0,
		gameStatusCopy: 'GAME NOT ACCESSIBLE WITHOUT ACCESS TO YOUR LOCATION',
		ageGate: {
			showError: false,
			showContent: false,
			verifyAge(isOlder) {
				if (isOlder) {
					const now = new Date();
					const nextDay = new Date(now.getTime() + 24 * 60 * 60 * 1000);
					sessionStorage.setItem('ageVerified', nextDay);
					document.getElementById('ageGateDialog').close();
					this.showContent = true;
				} else {
					document.getElementById('ageGateDialog').close();
					this.showError = true;
					document.getElementById('age-sorry').showModal();
				}
			}
		},
		init() {
			if (sessionStorage.getItem('darkMode') === 'true') {
				this.isDarkMode = true;
			}
			if (sessionStorage.getItem('lastScreen')) {
				// call the funciton to change the screen
				this.changeScreen(sessionStorage.getItem('lastScreen'));
			}
			this.$refs.audioTutorial.addEventListener('timeupdate', this.updateProgressTutorial.bind(this));

			if (sessionStorage.getItem('locationGranted')) {
				this.locationGranted = sessionStorage.getItem('locationGranted');
			}
			
			// const password = prompt("Please enter the password:");
			
			// if(btoa(password) === "c3RhZ2luZzIwMjM="){
			// 	this.authPW = true;
				
			// 	console.log(this.authPW);
			// } else {
			// 	alert('access denied');
			// 	// window.location.href = 'https://www.google.com';
				
			// }
		},
		toggleDarkMode() {
			this.isDarkMode = !this.isDarkMode;
			sessionStorage.setItem('darkMode', this.isDarkMode);
		},
		changeScreen(page){
			// scroll to top
			window.scrollTo(0, 0);

			// set focus to the body so that the screen reader will read the new content
			// document.activeElement.blur();
			// set focus to the dark mode toggle button so that the screen reader will read the new content
			document.getElementById('toggle-mode').focus();

			// save last screen
			sessionStorage.setItem('lastScreen', page);

			// if the tutorial is playing, pause it
			if (this.isPlayingTutorial) {
				this.togglePlayTutorial();
			}

			if(page != 'intro') this.currentScreen = page;
			if(page == 'listen'){
				let this_ = this;

				if (navigator.geolocation) {
					this_.gameStatusCopy = 'LOCATING...';
					navigator.geolocation.getCurrentPosition(function(position) {

						if (!position) {
							sessionStorage.removeItem('locationGranted');
							this_.locationGranted = false;
							return;
						}
						
						let latitude = position.coords.latitude;
						let longitude = position.coords.longitude;

						this_.userLatitude = latitude;
						this_.userLongitude = longitude;

						// console.log(longitude + ", " + latitude);

						// Define MSG bounding area
						const boundingArea = turf.polygon([[
							[-73.993948, 40.751535],  // Point 1
							[-73.99486, 40.7503],  // Point 2
							[-73.993122, 40.749548],  // Point 3
							[-73.992253, 40.750812],  // Point 4
							[-73.993948, 40.751535]   // Closing the polygon by repeating the first point
						]]);

						// // Define MSG bounding area
						// const swCorner = [-73.99491491305288, 40.749485266910256];
						// const neCorner = [-73.99215662073166, 40.751662203242404];

						// // Create a bounding box
						// const bbox = [...swCorner, ...neCorner];

						// // Create a Polygon from the bounding box
						// const boundingArea = turf.bboxPolygon(bbox);
						
						// Define the user's location as a point
						const userLocation = turf.point([longitude, latitude]);
				
						// Check if the user's location is inside the bounding area
						const isInside = turf.booleanPointInPolygon(userLocation, boundingArea);

						this_.isInside = isInside;

						// if the url has a query string, use that to determine if the user is inside the MSG
						const urlParams = new URLSearchParams(window.location.search);
						const isInsideParam = urlParams.get('isInside');
						if (isInsideParam) {
							this_.isInside = isInsideParam.toLowerCase() === 'true';
						}

						sessionStorage.setItem('locationGranted', true);
						this_.locationGranted = true;

						if(this_.gameInfo == null){
							this_.loadingGameInfo = true;
							axios.get(`${this_.beURL}${this_.beAPI}/schedule`)
							.then(response => {

								if (response.data.status === 'error') {
									// show error screen
									this_.currentScreen = 'error';
									this_.gameInfo = response.data;
									this_.loadingGameInfo = false;
									
								} else {
									
									axios.get(`${this_.beURL}${this_.beAPI}/summary/${response.data.id}`)
									.then(summaryResponse => {
										this_.gameInfo = response.data;
										this_.boxScore = summaryResponse.data;
										this_.gameInfo.status = this_.boxScore.status;
										this_.loadingGameInfo = false;
										if(this_.gameInfo.status === 'closed' || this_.gameInfo.status === "complete" ) this_.currentScreen = 'ended';
																				
										// check the gameInfo for the start time
										// the socket will not start until 5 minutes before the game starts
										const gameStartTime = new Date(this_.gameInfo.scheduled);
										const now = new Date();
										const fiveMinutes = 5 * 60 * 1000;
										const difference = gameStartTime - now;
										
										// if the game is about the start, or already started, start the socket
										if (difference < fiveMinutes) {
											this_.statsSocket();
										} else { // otherwise, start the socket 5 minutes before the game starts
											setTimeout(() => {
												this_.statsSocket();
											}, difference - fiveMinutes);
										}

										this_.statsSocket();

									})
									.catch(err => {
										console.error(err);
										this_.currentScreen = 'error';
										this_.loadingGameInfo = false;
									});
									
								}

							})
							.catch(err => {
								console.error(err);
								this_.currentScreen = 'error';
								this_.loadingGameInfo = false;
							});
						}
						
					});

				} else {
					sessionStorage.removeItem('locationGranted');
					this_.locationGranted = false;
				}
			}
		},
		initializeAgeGate() {
			const ageVerifiedDate = new Date(sessionStorage.getItem('ageVerified'));
			const now = new Date();
			if (ageVerifiedDate && ageVerifiedDate > now) {
				this.ageGate.showContent = true;
			} else {
				document.getElementById('ageGateDialog').showModal();
			}
		},
		statsSocket() {
			// console.log('starting socket');
			const socket = io.connect(this.beURL, { 
				path: this.bePATH 
			});
			socket.on('game-stats', (data) => {

				if (data.status === 'error') {
					// show error screen
					this.currentScreen = 'error';
					return;
				} 
				this.currentScreen = 'listen';
				this.boxScore = data;
				this.gameInfo.status = this.boxScore.status;
				if(this.gameInfo.status === 'closed' || this.gameInfo.status === "complete" ) this.currentScreen = 'ended';
			});

		},
		$refs: {
			audioTutorial: HTMLAudioElement,
			audioGame: HTMLAudioElement
		},
		togglePlayTutorial(){
			if (this.isPlayingTutorial) {
				this.$refs.audioTutorial.pause();
			} else {
				this.$refs.audioTutorial.play();
			}
			this.isPlayingTutorial = !this.isPlayingTutorial;
		},
		back15Tutorial(){
			this.$refs.audioTutorial.currentTime -= 15;
		},
		forward15Tutorial(){
			this.$refs.audioTutorial.currentTime += 15;
		},
		updateProgressTutorial() {
			this.progressValueTutorial = this.$refs.audioTutorial.currentTime / this.$refs.audioTutorial.duration;
		},
		togglePlayGame(){

			// get the videojs player 
			var player = videojs.getPlayer('video');

			if (player) {
				if (this.isPlayingGame) {
					player.pause();
				} else {
					player.play();
				}
			} else {

				if (this.isPlayingGame) {
					this.$refs.audioGame.pause();
				} else {
					this.$refs.audioGame.play();
				}
			}

			this.isPlayingGame = !this.isPlayingGame;
		},
		instantiateVideoJs() {
			this.$nextTick(() => {

				let userLatitude = this.userLatitude;
				let userLongitude = this.userLongitude;

				userLatitude = userLatitude ? userLatitude : 'No geolocation';
				userLongitude = userLongitude ? userLongitude : 'No geolocation';

				// make sure userLatitude and userLongitude are strings
				userLatitude = userLatitude.toString();
				userLongitude = userLongitude.toString();

				let userInside = this.isInside ? 'inside MSG' : 'outside MSG';

				const socket = io.connect(this.beURL, { 
					path: this.bePATH 
				});

				function formatLogData(message, type) {
					// Get current date/time in ISO format
					const dateTime = new Date().toISOString();
				
					// Get User-Agent string
					const userAgent = navigator.userAgent;

					// remove any commas from the user agent string
					const userAgentNoCommas = userAgent.replace(/,/g, '');
				
					// Get network connection information
					const connectionInfo = navigator.connection 
						? `(${navigator.connection.effectiveType} ${navigator.connection.downlink}Mbps ${navigator.connection.rtt}ms DataSaver: ${navigator.connection.saveData})` 
						: 'No connection info';
				
					// Ensure message is a string
					const formattedMessage = typeof message === 'string' ? message : JSON.stringify(message);

					// remove any commas from the message string, replace with a |
					const formattedMessageNoCommas = formattedMessage.replace(/,/g, '|');
				
					// Combine all data into a comma-separated string
					const logString = `${dateTime},${type},${userAgentNoCommas},${connectionInfo},${formattedMessageNoCommas},${userLatitude},${userLongitude},${userInside}`;
					
					return logString;
				}

				function sendLogToServer(logString) {
					// Send log data to server
					socket.emit('player-logging', logString);
				}

				function getUserLatLon() {
					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(function(position) {
							if (!position) {
								return { latitude: 'No geolocation', longitude: 'No geolocation' };
							}
							let latitude = position.coords.latitude;
							let longitude = position.coords.longitude;
							return { latitude, longitude };
						});
					} else {
						return { latitude: 'No geolocation', longitude: 'No geolocation' };
					}
				}
				
				
				// instantiate Video.js
				var player = videojs('video', {
					liveui: true,
				});

				const reloadOptions = {
					getSource: function(reload) {

						// log the error
						const logString = formatLogData('player reloaded', 'reloadSourceOnError');
						// console.log(logString);
						sendLogToServer(logString);

						// call reload() with a fresh source object
						// you can do this step asynchronously if you want (but the error dialog will
						// show up while you're waiting)
						  reload({
							src: 'https://stream-east.dreamcaster.app/live/knicks.m3u8',
							type: 'application/x-mpegURL'
						  });
					},

					// errorInterval specifies the minimum amount of seconds that must pass before
					// another reload will be attempted
					errorInterval: 5
				};

				player.reloadSourceOnError(reloadOptions);

				// stop the player from going fullscreen
				player.on('fullscreenchange', function(event, isFullscreen) {
					// console.log('fullscreenchange', isFullscreen);
					if (isFullscreen) {
						player.exitFullscreen();
					}
				});

				// when the player is played, move the playhead to the live edge
				player.on('play', function() {
					player.liveTracker.seekToLiveEdge();
					
				});

				player.on('play', function(event) {
					const logString = formatLogData(event, 'play');
					socket.emit('player-logging', logString);
				  });
				  
				  player.on('playing', function(event) {
					const logString = formatLogData(event, 'playing');
					socket.emit('player-logging', logString);
				  });
				  
				  player.on('loadeddata', function(event) {
					const logString = formatLogData(event, 'loadeddata');
					socket.emit('player-logging', logString);
				  });

				  player.on('error', function() {
					console.error('Error:', player.error());
					const logString = formatLogData(player.error(), 'error');
					socket.emit('player-logging', logString);
				  });


				  setInterval(function() {
					console.log('Player state:', player.readyState());
					const logString = formatLogData(player.readyState(), 'Player state');
					socket.emit('player-logging', logString);
				  }, 10000);


				  // using page visibility api, send a logstring to the server when the visibility changes, with the current visibility state
				  document.addEventListener("visibilitychange", function() {
					const logString = formatLogData(document.visibilityState, 'visibilitychange');
					socket.emit('player-logging', logString);
				  });
				
			});
		}
	}
}

window.appData = appData;

